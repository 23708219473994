<template>
    <div class="anleftnav darkBack">
        <div>
            <p>注册人数</p>
            <p>{{register}}</p>
        </div>
        <div>
            <p>在线运动人数</p>
            <p>{{line}}</p>
        </div>
        <div>
            <p>今日运动人数</p>
            <p>{{today}}</p>
        </div>
        <div>
            <p>总运动人数</p>
            <p>{{headcount}}</p>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            register:window.setData('百姓健身房-无法进入-注册人数', 3123),
            line:window.setData('百姓健身房-无法进入-在线运动人数', 12190),
            today:window.setData('百姓健身房-无法进入-今日运动人数', 52345),
            headcount:window.setData('百姓健身房-无法进入-总运动人数', 345231),
        }
    }
}
</script>

<style scoped>
.anleftnav{
    width:5.94rem;
    height:2.86rem;
    position: relative;
    display: flex;
    justify-content: space-around;
    flex-wrap:wrap;
    padding: 0.2rem;
    color:#A8DAEB;
    font-size: 0.14rem;
    text-align: center;
}
.anleftnav>div{
    width:2.44rem;
    height:0.9rem;
    background: url('../../../assets/images/borderBack.png') no-repeat;
    background-size:100% 100%;
    padding-top: 0.2rem;
    box-sizing: border-box;
}
.anleftnav>div>p:nth-child(2){
    color:#20D7FE;
    font-size: 0.22rem;
}
</style>